<template>
  <div class="home">
    <div class="all">

      <div class="top">
        <div class="title">资源查询</div>
        <div class="search">
          <div class="single">
            <div class="text">标题：</div>
            <el-input v-model="searchForm.title" placeholder="标题" clearable @clear="init" />
          </div>
          <div class="single">
            <div class="text">关键字：</div>
            <el-input v-model="searchForm.keyword" placeholder="关键字" clearable @clear="init" />
          </div>
          <div class="single">
            <div class="text">时间：</div>
            <el-date-picker
              v-model="time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="timechange"
            />
          </div>
          <div class="single">
            <div class="text">作者：</div>
            <el-input v-model="searchForm.authorUnit" placeholder="作者" clearable @clear="init" />
          </div>
          <el-button @click="init" class="btn">搜索</el-button>
        </div>
      </div>

      <div class="list">
        <my-no-data v-if="relist.length == 0" conentTop="50%"></my-no-data>
        <div class="single" v-for="item in relist" @click="godetail(item.resourceId,'look')">
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="description">{{ item.description }}</div>
            <div class="bottom">
              <div class="key">
                <span v-for="tag in item.keyword">{{ tag }}</span>
              </div>
              <el-button type="primary" icon="el-icon-download" size="small" class="btn"
                         @click="loadfile(item.upfileList)">下载</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { relist, reinfo, reload } from "@/api/resource"
import MyNoData from '@/components/noData/index.vue';
export default {
  name: '',
  components: {
    MyNoData
  },
  // 定义属性
  data() {
    return {
      time:'',
      relist: [],
      total: 0,
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        auditStatus:1,
        title: '',
        description: '',
        authorUnit: ''
      }
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async init() {
      let data = await relist(this.searchForm)
      console.log('data', data);
      if (data.code == 200) {
        this.relist = data.rows.map(v => {
          v.keyword = v.keyword.split(',')
          return v
        })
        this.tota = data.total
      }
    },
    addDomain() {

    },
    async godetail(id,type) {
      this.$router.push({
        path: 'upresource',
        query:{
          id,
          type
        }
      });
    },
    async loadfile(list) {
      list.map(async (v, index) => {
        setTimeout(() => {  // 修改了setTimeout传参方式 
          this.downloadFile(this.$uploadUrl + '/upFile/' + v.upfileId)
        }, 1000 * index)
      })
    },
    downloadFile(url) {
      let a = document.createElement('a') // 创建一个<a></a>标签
      a.href = url         //重点（如测试发现下载文件不存在/找不到，检查路径）
      // a.download = 'xxx.pdf'        
      a.style.display = 'none'           // 隐藏a标签
      document.body.appendChild(a)       // 将a标签追加到文档对象中
      a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove()
    },
    timechange(e){
      console.log('1',e);
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.init()
  },
}
</script>

<style lang="scss" scoped>
.home {
  background: #EDF4F8;
  box-sizing: border-box;
  padding-top: 25px;
  padding-bottom: 80px;
  overflow-y: auto;
}

.all {
  width: 73%;
  margin: 0 auto;


  .top {
    padding: 20px 40px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);

    .title {
      font-weight: 600;
      font-size: 22px;
      color: #333333;
      margin-bottom: 20px;
    }

    .search {
      display: flex;
      flex-wrap: wrap;



      .single {
        width: 42%;
        display: flex;
        align-items: center;
        font-weight: 600;
        margin-bottom: 10px;
        margin-right: 30px;

        .text {
          width: 100px;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
        }

        input {
          flex: 1;
        }
      }

      .btn {
        background: rgba(115, 141, 253, 0.12);
        border-radius: 4px;
        border: 1px solid #738DFD;
        height: 40px;
        font-weight: 400;
        font-size: 14px;
        color: #738DFD;

      }

    }
  }

  .list {
    margin-top: 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);

    .single {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      .content {
        padding: 20px 35px;
        box-sizing: border-box;
        position: relative;
        height: 180px;

        .title {
          font-weight: 600;
          font-size: 18px;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .description {
          margin-top: 10px;
          font-weight: 400;
          font-size: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;


        }

        .bottom {
          width: 95%;
          position: absolute;
          left: 35px;
          bottom: 20px;
          display: flex;
          justify-content: space-between;

          .key {
            span {
              display: inline-block;
              padding: 5px 15px;
              background: #DCF1FF;
              border-radius: 15px;
              border: 1px solid #89B0FF;
              font-weight: 400;
              font-size: 12px;
              color: #67ADF7;
              margin-right: 10px;
            }
          }

          .btn {
            background: #67ADF7;
            border-radius: 4px;
            border: none;
          }
        }
      }
    }
  }
}
::v-deep .el-date-editor .el-range-input, .el-date-editor .el-range-separator {
  font-size: 12px;
}

::v-deep .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}
</style>